// React
import React, { useEffect, useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-copy-to-clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";

// @mui
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import IosShareIcon from "@mui/icons-material/IosShare";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

// UserOperations
import getPubKeyUrl from "UserOperations/getPubKeyUrl";
import addPubKeyUrl from "UserOperations/addPubKeyUrl";

// A ---------------------------------------------------------------------- M

const IAMCard = (props) => {
  const { user, seedflow, pubKey, canSign } = UserAuth();
  const { t } = useTranslation();

  const [pbkurl, setPbkurl] = useState("");
  const [copied, setCopied] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const pbkurl = await getPubKeyUrl(user.uid);
      setPbkurl(pbkurl);
    };

    fetch();
  }, [user]);

  const handleCopy = () => {
    setCopied(true);
  };

  const handleSave = async () => {
    if (pbkurl) {
      await addPubKeyUrl(user.uid, pbkurl);
    }

    setEditable(false);
  };

  const handleEdit = () => {
    setEditable(true);
  };

  return (
    <Grid container spacing={1} data-testid="iam-card">
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "gray" }}>
          {t("identity_access_management")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {seedflow && (
          <Grid item container xs={12} spacing={1}>
            {/* Public Key Section */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="dense"
                id="Public Key"
                label={t("public_key")}
                value={pubKey}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <CopyToClipboard text={pubKey} onCopy={handleCopy}>
                      <Tooltip title={copied ? t("copied") : t("copy")} placement="top">
                        <IconButton color="primary">
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  ),
                }}
              />
            </Grid>

            {/* Public Key URL Section */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="dense"
                id="Public Key URL"
                label={t("pub_key_url")}
                value={pbkurl}
                onChange={(e) => setPbkurl(e.target.value)}
                disabled={!editable}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={editable ? t("save") : t("edit")} placement="top">
                      <IconButton color="primary" onClick={editable ? handleSave : handleEdit}>
                        {editable ? <CheckIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}

        {seedflow && (
          <Grid item container xs={12} sm={3}>
            <Grid item xs={6} sm={6}>
              <Button onClick={props.handleOpenPasswordExport} variant="outlined" color="primary" startIcon={<IosShareIcon />}>
                {t("export_secret")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button onClick={props.handleOpenForget} variant="outlined" color="error" startIcon={<DeleteForeverIcon />}>
                {t("forget_secret")}
              </Button>
            </Grid>
          </Grid>
        )}

        {canSign && !seedflow && (
          <Grid item container xs={12} sm={3}>
            <Grid item xs={6}>
              <Button onClick={props.handleOpenPasswordGenerate} variant="outlined" startIcon={<AddIcon />}>
                {t("generate_secret")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={props.handleOpenPasswordImport} variant="outlined" startIcon={<SaveAltIcon />}>
                {t("import_secret")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default IAMCard;
