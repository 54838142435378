// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const postGroup = async (userID, name) => {
  try {
    const groupRef = doc(collection(db, "groupsdata"));

    await setDoc(groupRef, {
      name: name,
      creator_uuid: userID,
      members: 1,
      created_on: unixTimestampInSeconds(),
    });

    const usersRef = collection(groupRef, "users");

    await setDoc(doc(usersRef, userID), {
      uid: userID,
      added_on: unixTimestampInSeconds(),
    });
  } catch (error) {
    console.error("Error in postGroup:", error.message);
  }
};

export default postGroup;
