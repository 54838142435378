// React
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// GroupOperations
import addMemberToGroup from "GroupOperations/addMemberToGroup";

// UserOperations
import getUUIDFromEmail from "UserOperations/getUUIDFromEmail";

// A ---------------------------------------------------------------------- M

const AddMember = ({ group, open, setOpen, handleSuccessfulAdded, handleErrorAdded }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [userAlreadyAdded, setUserAlreadyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    setUserNotFound(false);
    setUserAlreadyAdded(false);
  }, [newMemberEmail]);

  const handleAddMember = async () => {
    setIsLoading(true);

    try {
      const newMemberUID = await getUUIDFromEmail(newMemberEmail);
      if (!newMemberUID) {
        setUserNotFound(true);
        setIsLoading(false);
        return;
      }

      const result = await addMemberToGroup(newMemberUID, group.id);

      switch (result.status) {
        case "success":
          handleReset();
          handleSuccessfulAdded();
          break;
        case "user_already_added":
          setUserAlreadyAdded(true);
          break;
        case "error":
          handleErrorAdded();
          break;
        default:
          handleErrorAdded();
      }
    } catch (error) {
      handleErrorAdded();
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setNewMemberEmail("");
    setUserNotFound(false);
    setUserAlreadyAdded(false);
    setOpen(false);
  };

  const AddMemberForm = (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          margin="dense"
          label={t("email")}
          name="Email Address"
          id="Email Address"
          required
          variant="outlined"
          value={newMemberEmail}
          onChange={(e) => setNewMemberEmail(e.target.value)}
        />
      </Grid>
      {userNotFound && (
        <Grid item xs={12}>
          <Typography variant="body2" color="red" gutterBottom>
            {t("member_error_1")}
          </Typography>
        </Grid>
      )}
      {userAlreadyAdded && (
        <Grid item xs={12}>
          <Typography variant="body2" color="red" gutterBottom>
            {t("member_error_2")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("add_member")}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {AddMemberForm}
            <Grid container mt="3%">
              <Grid item xs={12}>
                <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={handleAddMember} disabled={!newMemberEmail}>
                  {t("add")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("add_member")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {AddMemberForm}
          <Grid item container xs={12} mt="3%">
            <Grid item xs={12}>
              <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={handleAddMember} disabled={!newMemberEmail}>
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddMember;
