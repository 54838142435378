// firebase
import { db } from "config/firebase";
import { doc, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// SafeTwin
import { genRndString } from "SafeTwin/crypto/cryptolibsodium";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const uploadFile = async (fileByteArray, fileName, fileType, tagID, isPublic = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(fileByteArray);
      console.log(fileName);
      console.log(fileType);
      console.log(tagID);
      console.log(isPublic);

      const storage = getStorage();
      const fileId = genRndString(32);

      console.log(fileId);

      const storageRef = ref(storage, `${fileId}/${fileName}`);
      const statusRef = doc(db, "uploadStatuses", fileId);

      // Set initial status
      await setDoc(statusRef, {
        status: "uploading",
        fileName: fileName,
        createdAt: unixTimestampInSeconds(),
      });

      // Create metadata with the correct MIME type and custom metadata
      // prettier-ignore
      const metadata = {
        contentType: fileType,
        customMetadata: {
          'isPublic': isPublic ? "true" : "false"
        }
      };

      // Add 'tagID' if present
      if (tagID) {
        metadata.customMetadata["tagID"] = tagID;
      }

      // Upload file with metadata
      try {
        console.log("uploading bytes...");
        await uploadBytes(storageRef, fileByteArray, metadata);
      } catch (error) {
        console.error("Error in uploading file:", error.message);
      }

      // Set status to uploaded
      await updateDoc(statusRef, {
        status: "uploaded",
      });

      // Listen for changes in the status
      const unsubscribe = onSnapshot(statusRef, async (doc) => {
        if (doc.exists) {
          const status = doc.data().status;
          console.log("File status:", status);

          if (status === "valid") {
            unsubscribe(); // Stop listening for changes
            const downloadURL = await getDownloadURL(storageRef);
            console.log(fileId);
            console.log(downloadURL);
            resolve({ uploadID: fileId, downloadURL: downloadURL });
          } else if (status === "invalid" || status === "error") {
            unsubscribe(); // Stop listening for changes
            reject(new Error("File is invalid"));
          }
        }
      });
    } catch (error) {
      console.error("Error in uploadFile:", error.message);
      reject(error);
    }
  });
};

export default uploadFile;
