// react
import React, { useState, useEffect } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// @mui
import { IconButton, Dialog } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import ImageTable from "./ImageTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const ImageViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();

  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    handleOpenView();
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType.startsWith("image/")) {
        setSelectedImage(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const handleCloseDialog = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {tag && <ImageTable tag={tag} isTagGroupMember={isTagGroupMember} checkVerification={checkVerification} handleView={handleView} handleFileClick={handleFileClick} />}

      {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} handleFileClick={handleFileClick} />}

      {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}

      {selectedImage && (
        <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <IconButton
            sx={{
              position: "absolute",
              top: "0%",
              right: "0%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img src={selectedImage} alt="img" />
        </Dialog>
      )}
    </>
  );
};

export default ImageViewer;
