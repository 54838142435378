// React
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileOpenIcon from "@mui/icons-material/FileOpen";

// Components
import MetadataField from "./MetadataField";
import SoggettoForm from "./SoggettoForm";

// MetadataSchema
import { metadataSchema, metadataSections } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}));

const CDNFileForm = ({ isMobile, source, blobURL, fileName, documentProperties, setDocumentProperties, handleFileChange, handleFileClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent("");
  };

  const handleChangeDocumentProperties = (fieldKey, value) => {
    setDocumentProperties({ ...documentProperties, [fieldKey]: value });
  };

  const handleAddSoggetto = () => {
    setDocumentProperties({
      ...documentProperties,
      soggetti: [
        ...documentProperties.soggetti,
        {
          ruolo: "",
          tipoSoggetto: "",
          cognome: "",
          nome: "",
          codiceFiscale: "",
          partitaIva: "",
          indirizziDigitaliDiRiferimento: "",
          denominazioneOrganizzazione: "",
          denominazioneUfficio: "",
          denominazioneAmministrazioneCodiceIPA: "",
          denominazioneAmministrazioneAOOCodiceIPAAOO: "",
          denominazioneAmministrazioneUORCodiceIPAUOR: "",
          denominazioneAmministrazione: "",
          denominazioneSistema: "",
        },
      ],
    });
  };

  const handleSoggettoChange = (index, field, value) => {
    const updatedSoggetti = documentProperties.soggetti.map((soggetto, i) => (i === index ? { ...soggetto, [field]: value } : soggetto));
    setDocumentProperties({ ...documentProperties, soggetti: updatedSoggetti });
  };

  const handleRemoveSoggetto = (index) => {
    const updatedSoggetti = documentProperties.soggetti.filter((_, i) => i !== index);
    setDocumentProperties({ ...documentProperties, soggetti: updatedSoggetti });
  };

  return (
    <>
      <Grid container spacing={5}>
        {/* Section: File Upload */}
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("select_file")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={1}>
              <Typography>{t("file")} *</Typography>
            </Grid>
            <Grid item xs={12} sm={11}>
              <input
                className={classes.input}
                id="new-upload-button"
                type="file"
                accept="application/pdf, text/xml, application/xml, text/plain, text/csv, image/png"
                onChange={(e) => handleFileChange(e.target)}
              />
              <label htmlFor="new-upload-button">
                <Button startIcon={<FileUploadIcon />} variant="contained" component="span" sx={{ width: isMobile ? "100%" : "30%" }}>
                  {t("select_file")}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Typography>{t("file_name")} *</Typography>
            </Grid>
            {source ? (
              <Grid item xs={12} sm={11}>
                <Button startIcon={<FileOpenIcon />} onClick={() => handleFileClick(blobURL)} variant="contained" sx={{ width: isMobile ? "100%" : "30%" }}>
                  {fileName || t("selected_file")}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={10.5}>
                <Typography>{t("no_file_selected")}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {source && (
          <>
            <Grid item container spacing={3}>
              {metadataSections.map((section) =>
                section.title === "Soggetti" ? (
                  <Grid item container spacing={3} key={section.title}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="gray" fontWeight="bold">
                        {section.title}
                      </Typography>
                      <Divider />
                    </Grid>

                    {documentProperties.soggetti.map((soggetto, index) => (
                      <SoggettoForm
                        key={index}
                        soggetto={soggetto}
                        index={index}
                        soggettoFields={section.fields}
                        handleSoggettoChange={handleSoggettoChange}
                        handleRemoveSoggetto={handleRemoveSoggetto}
                        handleClickOpen={handleClickOpen}
                      />
                    ))}

                    <Grid item xs={12}>
                      <Button variant="contained" onClick={handleAddSoggetto}>
                        Aggiungi Soggetto
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <React.Fragment key={section.title}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="gray" fontWeight="bold">
                        {section.title}
                      </Typography>
                      <Divider />
                    </Grid>

                    {section.fields.map((fieldKey) => (
                      <MetadataField
                        key={fieldKey}
                        fieldKey={fieldKey}
                        metadata={metadataSchema}
                        documentProperties={documentProperties}
                        handleChange={handleChangeDocumentProperties}
                        handleClickOpen={handleClickOpen}
                      />
                    ))}
                  </React.Fragment>
                )
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CDNFileForm;
