// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const updateField = async (tagID, field, value) => {
  const tagRef = doc(db, "tagsdata", tagID);
  try {
    await updateDoc(tagRef, { [field]: value });
    console.log(`${field} updated successfully`);
  } catch (error) {
    console.error(`Error updating ${field}:`, error.message);
  }
};

export default updateField;
