// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { Dialog, DialogTitle, DialogContent, Typography, Divider, Grid } from "@mui/material";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@mui/lab";

// Material UI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// TagOperations
import fetchDataboxEvents from "TagOperations/fetchDataboxEvents";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DataboxLogDialog = ({ isOpen, onClose, databoxID }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (isOpen && databoxID) {
      const loadEvents = async () => {
        const fetchedEvents = await fetchDataboxEvents(databoxID);
        setEvents(fetchedEvents);
      };
      loadEvents();
    }
  }, [isOpen, databoxID]);

  // Icon mapping for action types
  const getIcon = (type) => {
    switch (type) {
      case "certification":
        return <CheckCircleIcon sx={{ color: "#2196f3" }} />;
      case "certification_error":
        return <CheckCircleIcon sx={{ color: "red" }} />;
      case "verification":
        return <VerifiedIcon sx={{ color: "#4caf50" }} />;
      case "verification_error":
        return <VerifiedIcon sx={{ color: "red" }} />;
      case "upload":
        return <UploadFileIcon sx={{ color: "#2196f3" }} />;
      case "upload_error":
        return <UploadFileIcon sx={{ color: "red" }} />;
      case "error":
        return <ErrorIcon sx={{ color: "#f44336" }} />;
      default:
        return <CheckCircleIcon />;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "rgba(38, 50, 56, 0.95)",
          color: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>{conservSostL1 ? t("archive") : t("databox")} Event Log</DialogTitle>
      <DialogContent>
        <Divider sx={{ bgcolor: "#ffa500", mb: 3 }} />
        <Timeline position="left">
          {events.map((event, index) => (
            <TimelineItem key={index} sx={{ marginBottom: 2 }}>
              <TimelineSeparator>
                <TimelineDot>{getIcon(event.eventType)}</TimelineDot>
                {index < events.length - 1 && <TimelineConnector sx={{ bgcolor: "#ffa500" }} />}
              </TimelineSeparator>
              <TimelineContent sx={{ minWidth: "600px" }}>
                {event.eventType && (
                  <Typography variant="body2" fontWeight="bold">
                    {event.eventType.toUpperCase()}
                  </Typography>
                )}
                {event.timestamp && (
                  <Typography variant="subtitle2" fontWeight="bold">
                    {convertTimestamp(event.timestamp)}
                  </Typography>
                )}
                <Grid container>
                  {event.description && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        {event.description}
                      </Typography>
                    </Grid>
                  )}
                  {event.metadata?.certificationID && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Certification ID: {event.metadata?.certificationID}
                      </Typography>
                    </Grid>
                  )}
                  {event.metadata?.documentID && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Document ID: {event.metadata?.documentID}
                      </Typography>
                    </Grid>
                  )}
                  {event.performedBy && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Performed By: {event.performedBy}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};

export default DataboxLogDialog;
