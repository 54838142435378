// react
import React, { useState, useEffect, useCallback } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import {
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

// components
import CDNFileFilterFormDialog from "./CDNFileFilterFormDialog";
import CDNFileTable from "./CDNFileTable";
import CDNPreservationProofDialog from "./CDNPreservationProofDialog";

// firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchCDNFileRecords from "RecordOperations/CDNFileRecords/fetchCDNFileRecords";

// MetadataSchemaFilters
import { metadataSchemaFilters } from "./metadataSchemaFilters";

// initialFilters
import { initialFilters } from "./initialFilters";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileViewer = ({ tag, isTagGroupMember, isTagGroupMemberReadWrite, openUploadGenDrawer }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [CDNFileRecords, setCDNFileRecords] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isPreservationProofDialogOpen, setIsPreservationProofDialogOpen] = useState(false);
  const [record, setRecord] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const generateFilterLabels = (metadataSchemaFilters) => {
    const labels = {};
    Object.keys(metadataSchemaFilters).forEach((key) => {
      labels[key] = metadataSchemaFilters[key].label;
    });
    return labels;
  };

  const filterLabels = generateFilterLabels(metadataSchemaFilters);

  const getCDNFileRecords = useCallback(async (criteria = {}) => {
    try {
      const records = await fetchCDNFileRecords(tag.id, isTagGroupMember, criteria);
      if (records) {
        console.log(records);
        records.sort((a, b) => b.timestamp - a.timestamp);
        setCDNFileRecords(records);
      }
    } catch (error) {
      console.error("Error in fetching record:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(signaturesCollectionRef, where("type", "==", "CDNFile"), where("timestamp", ">=", currentTimestampInSecondsString));
    const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          getCDNFileRecords();
        }
      });
    });

    getCDNFileRecords(true);

    return () => {
      newAdditionsUnsubscribe();
    };
  }, []);

  const handleFilter = (criteria) => {
    setSelectedFilters(criteria);
    getCDNFileRecords(criteria);
  };

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleResetFilters = () => {
    setSelectedFilters(initialFilters);
    getCDNFileRecords();
  };

  const handleOpenPreservationProofDialog = (record) => {
    setRecord(record);
    setIsPreservationProofDialogOpen(true);
  };

  const handleClosePreservationProofDialog = () => {
    setIsPreservationProofDialogOpen(false);
  };

  const filtersAreActive = () => {
    return Object.keys(selectedFilters).some((key) => selectedFilters[key] !== initialFilters[key]);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      setSelectedFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType.startsWith("image/")) {
        setSelectedFile(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const renderFileDialog = () => {
    if (!selectedFileType) {
      return null;
    }

    switch (selectedFileType) {
      case "application/pdf":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <pre>{xmlContent}</pre>
            </Box>
          </Dialog>
        );

      case "text/plain":
      case "text/csv":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <IconButton
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                zIndex: 1,
                color: "red",
              }}
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseOutlinedIcon />
            </IconButton>
            <img src={selectedFile} alt="img" />
          </Dialog>
        );

      default:
        return null;
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container spacing={3}>
        {/* Selected Filter */}
        {filtersAreActive() && (
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <Typography variant="subtitle1" fontWeight="bold" mr="1%">
              {t("selected_filters")}:
            </Typography>
            {Object.keys(selectedFilters).map((key) => selectedFilters[key] && <Chip key={key} label={`${filterLabels[key]}: ${selectedFilters[key]}`} />)}
            <Grid item container xs={12} justifyContent="flex-end">
              <Button variant="contained" onClick={handleResetFilters} startIcon={<RefreshIcon />} size="small">
                {t("reset_filters")}
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Documents Table */}
        {CDNFileRecords && CDNFileRecords.length > 0 ? (
          <Grid item xs={12}>
            <CDNFileTable
              records={CDNFileRecords}
              handleOpenPreservationProofDialog={handleOpenPreservationProofDialog}
              isTagGroupMember={isTagGroupMember}
              handleOpenFilterDialog={handleOpenFilterDialog}
              isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
              openUploadGenDrawer={openUploadGenDrawer}
              handleFileClick={handleFileClick}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CDNFileTable
              records={[]}
              handleOpenPreservationProofDialog={handleOpenPreservationProofDialog}
              isTagGroupMember={isTagGroupMember}
              handleOpenFilterDialog={handleOpenFilterDialog}
              isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
              openUploadGenDrawer={openUploadGenDrawer}
              handleFileClick={handleFileClick}
            />
          </Grid>
        )}
      </Grid>

      {/* Filters Dialog */}
      {isFilterDialogOpen && <CDNFileFilterFormDialog open={isFilterDialogOpen} onClose={handleCloseFilterDialog} onFilter={handleFilter} currentFilters={selectedFilters} />}

      {/* Preservation Proof Dialog */}
      {isPreservationProofDialogOpen && record && (
        <CDNPreservationProofDialog record={record} open={isPreservationProofDialogOpen} setOpen={setIsPreservationProofDialogOpen} onClose={handleClosePreservationProofDialog} />
      )}

      {renderFileDialog()}
    </>
  );
};

export default CDNFileViewer;
