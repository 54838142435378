// react
import React, { useEffect, useState } from "react";

// prop-types
import PropTypes from "prop-types";

// contexts
import { UserAuth } from "context/AuthContext";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  IconButton,
  Typography,
  TextField,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Tooltip,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";

// SafeTwin
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";

// TagOperations
import createDataBox from "TagOperations/createDataBox";

// UserOperations
import addSeenTag from "UserOperations/addSeenTag";

// A ---------------------------------------------------------------------- M

const DataBoxCreate = ({ open, setOpen, handleSuccessful }) => {
  const { user, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [tipologiaDocumentale, setTipologiaDocumentale] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [tagID, setTagID] = useState("");
  const [isCreatingDatabox, setIsCreatingDatabox] = useState(false);

  const steps = [
    { label: t("name"), description: t("new_databox_1") },
    { label: t("description"), description: t("new_databox_2") },
    conservSostL1 && { label: t("document_type"), description: t("new_databox_3") },
    { label: t("qr_code"), description: conservSostL1 ? t("new_databox_5") : t("new_databox_4") },
  ].filter(Boolean);

  useEffect(() => {
    const handlePopstate = () => open && handleReset();
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  const handleSave = async () => {
    setIsCreatingDatabox(true);
    try {
      await createDataBox(tagID, user.uid, name, conservSostL1 ? tipologiaDocumentale : undefined);
      await addSeenTag(tagID, user.uid, name, notes);
      handleSuccessful();
    } catch (error) {
      console.log("Error in creating the archive:", error.message);
    } finally {
      setIsCreatingDatabox(false);
      handleReset();
    }
  };

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const generateNewString = () => setTagID(genRndStringPrintable(10));

  const handleReset = () => {
    setName("");
    setNotes("");
    setTipologiaDocumentale("");
    setActiveStep(0);
    setTagID("");
    setIsCreatingDatabox(false);
    setOpen(false);
  };

  const stepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <TextField margin="dense" label={t("name")} fullWidth required variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" sx={{ width: { xs: "100%", sm: "30%" } }} onClick={handleNext} disabled={!name}>
                {t("continue")}
              </Button>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <TextField margin="dense" label={t("description")} fullWidth variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} multiline rows={2} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleBack}>
                {t("back")}
              </Button>
              <Button
                variant="contained"
                sx={{ width: { xs: "48%", sm: "30%" } }}
                onClick={() => {
                  generateNewString();
                  handleNext();
                }}
              >
                {t("continue")}
              </Button>
            </Box>
          </>
        );
      case 2:
        if (conservSostL1) {
          return (
            <>
              <FormControl fullWidth required margin="dense">
                <Select value={tipologiaDocumentale} onChange={(e) => setTipologiaDocumentale(e.target.value)}>
                  <MenuItem value="Fatture">Fatture</MenuItem>
                  <MenuItem value="Contratti">Contratti</MenuItem>
                  <MenuItem value="Libri e registri contabili">Libri e registri contabili</MenuItem>
                  <MenuItem value="DDT">DDT</MenuItem>
                  <MenuItem value="Ordini di acquisto NSO">Ordini di acquisto NSO</MenuItem>
                  <MenuItem value="OIL - mandati e reversali">OIL - mandati e reversali</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleBack}>
                  {t("back")}
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: { xs: "48%", sm: "30%" } }}
                  onClick={() => {
                    generateNewString();
                    handleNext();
                  }}
                  disabled={!tipologiaDocumentale}
                >
                  {t("continue")}
                </Button>
              </Box>
            </>
          );
        } else {
          return (
            <>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                <QRCodeSVG value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tagID}`} style={{ height: 150, width: 150 }} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleBack}>
                  {t("back")}
                </Button>
                <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleSave}>
                  {t("save")}
                </Button>
              </Box>
            </>
          );
        }
      case 3:
        return (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
              <QRCodeSVG value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tagID}`} style={{ height: 150, width: 150 }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleBack}>
                {t("back")}
              </Button>
              <Button variant="contained" sx={{ width: { xs: "48%", sm: "30%" } }} onClick={handleSave}>
                {t("save")}
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const renderStepContent = steps.map((step, index) => (
    <Step key={step.label}>
      <StepLabel>
        <b>{t(step.label)}</b>
      </StepLabel>
      <StepContent>
        <Typography>{t(step.description)}</Typography>
        {stepContent(index)}
      </StepContent>
    </Step>
  ));

  const DialogTitleContent = () => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h5" fontWeight="bold">
          {conservSostL1 ? t("new_archive") : t("new_databox")}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={t("close")} placement="top">
          <IconButton onClick={handleReset} color="error" edge="end">
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const innerContent = (
    <Grid container>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {renderStepContent}
        </Stepper>
      </Grid>
    </Grid>
  );

  return isCreatingDatabox ? (
    <LoadingDialog open={isCreatingDatabox} />
  ) : isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "80%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
      data-testid="databox-create-modal"
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {conservSostL1 ? t("new_archive") : t("new_databox")}
            </Typography>
          </Grid>
        </Grid>
        {innerContent}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 0.5,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
      data-testid="databox-create-modal"
    >
      <DialogTitle>
        <DialogTitleContent />
      </DialogTitle>
      <Divider />
      <DialogContent>{innerContent}</DialogContent>
    </Dialog>
  );
};

DataBoxCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSuccessful: PropTypes.func.isRequired,
};

export default DataBoxCreate;
