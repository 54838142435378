// React
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// TagOperations
import getTagTypes from "TagOperations/getTagTypes";
import addForwardRoute from "TagOperations/addForwardRoute";

// A ---------------------------------------------------------------------- M

const NewTargetEndpoint = ({ tag, open, setOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [endpoint, setEndpoint] = useState("");
  const [type, setType] = useState("REST");
  const [status, setStatus] = useState(true);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [headers, setHeaders] = useState([{ key: "", value: "" }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const fetch = async () => {
      const types = await getTagTypes(tag.id);
      setTypes(types);
    };

    fetch();
  }, [tag]);

  const handleReset = () => {
    setEndpoint("");
    setType("REST");
    setStatus(true);
    setSelectedTypes([]);
    setHeaders([{ key: "", value: "" }]);
    setOpen(false);
  };

  const submitForwardRoute = async () => {
    setLoading(true);

    const body = {
      tag: tag,
      endpoint: endpoint,
      type: type,
      status: status,
      selectedTypes: selectedTypes,
      headers: headers,
    };

    await addForwardRoute(tag.id, body);

    setLoading(false);
    handleReset();
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  const handleRemoveHeader = (index) => {
    const newHeaders = headers.filter((_, idx) => idx !== index);
    setHeaders(newHeaders);
  };

  const handleHeaderChange = (index, event) => {
    const { name, value } = event.target;
    const newHeaders = headers.map((header, idx) => {
      if (idx === index) {
        return { ...header, [name]: value };
      }
      return header;
    });
    setHeaders(newHeaders);
  };

  const TargetForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField margin="dense" id="endpoint" label={t("endpoint")} name="Endpoint" required value={endpoint} onChange={(e) => setEndpoint(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="type-select-label">{t("type")}</InputLabel>
          <Select labelId="type-select-label" id="type" label={t("type")} value={type} onChange={(e) => setType(e.target.value)}>
            <MenuItem value={"REST"}>REST</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="status-select-label">{t("status")}</InputLabel>
          <Select labelId="status-select-label" id="status" label={t("status")} value={status} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value={true}>{t("enabled").toUpperCase()}</MenuItem>
            <MenuItem value={false}>{t("disabled").toUpperCase()}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <Select
            id="data-types"
            displayEmpty
            multiple
            value={selectedTypes}
            onChange={(e) => setSelectedTypes(e.target.value)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography color="gray">{t("choose_datatypes")}</Typography>;
              }
              return selected.join(", ");
            }}
          >
            {types.length !== 0 &&
              types.map((type, index) => (
                <MenuItem key={index} value={type}>
                  <Checkbox checked={selectedTypes.indexOf(type) > -1} />
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color="gray" fontWeight="bold">
          Headers
        </Typography>
      </Grid>
      {headers.map((header, index) => (
        <Grid key={index} item container xs={12} spacing={1}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <TextField label={t("key")} name="key" required value={header.key} onChange={(e) => handleHeaderChange(index, e)} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label={t("value")} name="value" required value={header.value} onChange={(e) => handleHeaderChange(index, e)} fullWidth />
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button variant="outlined" startIcon={<DeleteForeverIcon />} color="error" onClick={() => handleRemoveHeader(index)}>
              {t("remove")}
            </Button>
          </Grid>
        </Grid>
      ))}
      <Grid item container xs={12} justifyContent="flex-end">
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddHeader}>
          {t("add")}
        </Button>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("new_endpoint")}
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {TargetForm}
            <Grid container mt="3%">
              <Grid item xs={12}>
                <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={submitForwardRoute} disabled={!endpoint}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_endpoint")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {loading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {TargetForm}
          <Grid item container xs={12} mt="3%">
            <Grid item xs={12}>
              <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={submitForwardRoute} disabled={!endpoint}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NewTargetEndpoint;
