// react
import React, { useState, useEffect } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// components
import LikeSirtiTable from "./LikeSirtiTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const LikeSirtiViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();

  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    handleOpenView();
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {tag && <LikeSirtiTable tag={tag} isTagGroupMember={isTagGroupMember} checkVerification={checkVerification} handleView={handleView} />}

      {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} />}

      {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}
    </>
  );
};

export default LikeSirtiViewer;
