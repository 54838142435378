// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// UserOperations
import addSignature from "UserOperations/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamInfo = async (isPublic, userID, dataBody, dataCertification, timestamp, databoxID) => {
  try {
    // Record transaction id (txid)
    const txid = dataCertification.txid;

    // Create new record in the recordsdata collection, using txid as document id
    const recordRef = doc(db, "recordsdata", txid);

    // Set the new record
    const recordData = {
      txid: txid,
      type: "info",
      ...(databoxID && { tdr: databoxID }),
      creator_uuid: userID,
      data: {
        text: dataBody.text,
        timestamp: timestamp,
      },
      text: dataBody.text,
      timestamp: timestamp,
      public: isPublic,
      version: 1,
    };

    console.log(recordData);

    await setDoc(recordRef, recordData);

    if (databoxID) {
      // Create the record link to "tagsdata" collection, using txid as foreign key
      const tagRef = doc(db, "tagsdata", databoxID);

      // Signatures collection reference related to the tag
      const recordsRef = collection(tagRef, "signatures");

      // Set the new record link to the "signatures" subcollection
      const signatureData = {
        txid: txid,
        type: "info",
        creator_uuid: userID,
        text: dataBody.text,
        timestamp: timestamp,
        public: isPublic,
        db_id: 1,
      };

      console.log(signatureData);

      await setDoc(doc(recordsRef, txid), signatureData);
    }

    // Add the signature to "mysigs" subcollection of "userdata" collection
    databoxID ? await addSignature(userID, txid, timestamp, "info", databoxID) : await addSignature(userID, txid, timestamp, "info");

    const result = {
      txid: txid,
      success: true,
    };

    console.log(result);

    return result;
  } catch (error) {
    console.error("Error in sendDataStreamInfo:", error.message);

    const result = {
      success: false,
      error: error.message,
    };

    return result;
  }
};

export default sendDataStreamInfo;
