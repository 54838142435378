// Firebase
import { db } from "config/firebase";
import { collection, doc, setDoc } from "firebase/firestore";

/**
 * Logs an event to the specified databox's events subcollection.
 *
 * @param {string} databoxID - The ID of the databox.
 * @param {string} eventType - The type of the event (e.g., 'certification', 'upload').
 * @param {number} timestamp - The timestamp of the event.
 * @param {string} description - A brief description of the event.
 * @param {Object} metadata - Additional metadata related to the event.
 * @param {string} performedBy - The entity who performed the event.
 * @param {string} txid - The transaction ID associated with the event (optional).
 * @param {string} severity - The severity level of the event (e.g., 'informative', 'warning', 'error', 'critical').
 * @returns {Promise<void>} - A promise that resolves when the event is logged.
 */

// A ---------------------------------------------------------------------- M

const logEvent = async (databoxID, eventType, timestamp, description, metadata, performedBy, txid = null, severity = "informative") => {
  try {
    if (!databoxID) {
      console.error("Databox ID is required to log an event");
      return;
    }

    // Reference to the events subcollection within the specified databox
    const eventsRef = collection(db, "tagsdata", databoxID, "events");

    // Generate a new documentID
    const eventDocRef = doc(eventsRef);

    // Event data structure
    const eventData = {
      eventType, // Type of the event (e.g., certification, verification)
      timestamp, // Unix timestamp of the event
      performedBy, // User ID or system ID responsible for the event
      description, // Short description of the event
      metadata, // Additional metadata relevant to the event
      databoxID, // Databox ID for context
      txid, // Associated transaction ID (if available)
      severity, // Severity level of the event (default: 'info')
    };

    // Write the event data to the database
    await setDoc(eventDocRef, eventData);

    console.log(`Event of type '${eventType}' logged successfully for databox '${databoxID}'.`);
  } catch (error) {
    console.error("Error logging event:", error.message);
  }
};

export default logEvent;
