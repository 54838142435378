// Function to convert from ISO format to Unix timestamp in seconds

// A ---------------------------------------------------------------------- M

const isoToUnixTimestamp = (isoString) => {
  // Parse the ISO format datetime string
  let date = new Date(isoString);

  // Get the Unix timestamp in milliseconds and convert to seconds
  let unixTimestamp = Math.floor(date.getTime() / 1000);

  // Return the Unix timestamp as a string
  return unixTimestamp.toString();
};

export default isoToUnixTimestamp;
