// React
import React, { useState, useEffect } from "react";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";

// Components
import MetadataField from "./MetadataField";
import { Puller } from "ui-components/Puller";

// MetadataSchemaFilters
import { metadataSchemaFilters, metadataSectionsFilters } from "./metadataSchemaFilters";

// A ---------------------------------------------------------------------- M

const CDNFileFilterFormDialog = ({ open, setOpen, onClose, onFilter, currentFilters }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [filterCriteria, setFilterCriteria] = useState(currentFilters);

  useEffect(() => {
    setFilterCriteria(currentFilters);
  }, [currentFilters]);

  const handleChange = (fieldKey, value) => {
    setFilterCriteria({ ...filterCriteria, [fieldKey]: value });
  };

  const handleApplyFilters = () => {
    onFilter(filterCriteria);
    onClose();
  };

  const CDNFilterDocumentsForm = (
    <Grid container spacing={5}>
      <Grid item container spacing={3}>
        {metadataSectionsFilters.map((section) => (
          <React.Fragment key={section.title}>
            <Grid item xs={12}>
              <Typography variant="h6" color="gray" fontWeight="bold">
                {section.title}
              </Typography>
              <Divider />
            </Grid>
            {section.fields.map((fieldKey) => (
              <MetadataField key={fieldKey} fieldKey={fieldKey} metadata={metadataSchemaFilters} documentProperties={filterCriteria} handleChange={handleChange} />
            ))}
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" fullWidth startIcon={<SearchIcon />} onClick={handleApplyFilters}>
          {t("search")}
        </Button>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("search")}
            </Typography>
          </Grid>
        </Grid>
        {CDNFilterDocumentsForm}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("search")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{CDNFilterDocumentsForm}</DialogContent>
    </Dialog>
  );
};

CDNFileFilterFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default CDNFileFilterFormDialog;
