// TagOperations
import getForwardRoutes from "TagOperations/getForwardRoutes";

// A ---------------------------------------------------------------------- M

const getTargetsData = async (databoxID) => {
  try {
    const targetsArray = await getForwardRoutes(databoxID);
    const enabledTargetsCount = targetsArray.filter((target) => target.enabled).length;

    console.log(targetsArray);
    console.log(enabledTargetsCount);

    return {
      targetsArray,
      enabledTargetsCount,
    };
  } catch (error) {
    console.error("Error in getTargets:", error.message);
    return {
      targetsArray: [],
      enabledTargetsCount: 0,
    };
  }
};

export default getTargetsData;
