// Firebase
import { db } from "config/firebase";
import { doc, getDoc, deleteDoc, updateDoc, deleteField } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Deletes a user's mnemonic and associated data from Firestore and local storage.
 * This function deletes the user's seed from the 'userseeds' collection,
 * removes the public_key property from the 'usercontacts' collection, and clears related local storage items.
 *
 * @param {Object} user - The user object containing at least the uid property.
 */
export const forgetMnemonic = async (user) => {
  try {
    const uuid = user.uid;

    // Delete seed from 'userseeds' collection
    const userSeedDocRef = doc(db, "userseeds", uuid);
    await deleteDoc(userSeedDocRef);

    // Clear local storage
    window.localStorage.removeItem("seed");
    window.localStorage.removeItem("nonce");

    // Delete public key from 'usercontacts' collection
    const userContactsDocRef = doc(db, "usercontacts", uuid);
    const userSnap = await getDoc(userContactsDocRef);
    const userData = userSnap.data();
    const public_key = userData.public_key || null;

    await updateDoc(userContactsDocRef, {
      public_key: deleteField(),
    });

    return public_key;
  } catch (error) {
    console.error("Error in fforgetMnemonic:", error.message);
    return null;
  }
};
