// react
import React, { useState, useEffect } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  useMediaQuery,
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Button,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, ExpandMore as ExpandMoreIcon, Download as DownloadIcon, FileOpen as FileOpenIcon } from "@mui/icons-material";

// components
import { Puller } from "ui-components/Puller";

// metadataSchema
import { metadataSchema, metadataSections } from "./metadataSchema";

// utils
import convertTimestamp from "utils/convertTimestamp";

// storage
import downloadFile from "storage/downloadFile";

// A ---------------------------------------------------------------------- M

const CDNFileDetailsDialog = ({ open, onClose, record, handleFileClick }) => {
  console.log(record);

  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [expandedDocuments, setExpandedDocuments] = useState(false);
  const [expandedSoggetti, setExpandedSoggetti] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  const toggleDocumentsExpansion = () => {
    setExpandedDocuments((prevExpanded) => !prevExpanded);
  };

  const toggleSoggettiExpansion = () => {
    setExpandedSoggetti((prevExpanded) => !prevExpanded);
  };

  const handleDocumentOpen = (doc) => {
    const attachment = record.data?.find((file) => file.fileName === doc.nomeDocumento)?.attachment;

    if (attachment) {
      handleFileClick(attachment);
    } else {
      console.error("Cannot open the selected document.");
    }
  };

  const handleDownloadFile = (doc) => {
    const attachment = record.data?.find((file) => file.fileName === doc.nomeDocumento)?.attachment;
    const fileName = record.data?.find((file) => file.fileName === doc.nomeDocumento)?.fileName;

    if (attachment && fileName) {
      downloadFile(attachment, fileName);
    } else {
      console.error("Cannot download the selected document.");
    }
  };

  const DetailsForm = (
    <Grid container spacing={3}>
      {record.documenti && record.documenti.length > 0 && (
        <Grid item xs={12}>
          <Accordion expanded={expandedDocuments} onChange={toggleDocumentsExpansion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="documents-content" id="documents-header">
              <Typography variant="h6" fontWeight="bold">
                {t("uploaded_documents")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={5}>
                {record.documenti.map((documento, documentoIndex) => (
                  <Grid item container spacing={1} key={documentoIndex}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body1" fontWeight="bold">
                        {`${t("document")} ${documentoIndex + 1}: ${documento.nomeDocumento}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <b>{t("ID")}:</b> {documento.idDocumento}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <b>{t("Formato")}:</b> {documento.formatoDocumento}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <b>{t("Hash")}:</b> {documento.hash}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <b>{t("Algoritmo")}:</b> {documento.algoritmo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <b>{t("Numero Allegati")}:</b> {documento.numeroAllegati}
                      </Typography>
                    </Grid>
                    {documento.descrizioneAllegato && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                          <b>{t("Descrizione Allegato")}:</b> {documento.descrizioneAllegato}
                        </Typography>
                      </Grid>
                    )}
                    {documento.idDocumentoPrimario && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                          <b>{t("ID Documento Primario")}:</b> {documento.idDocumentoPrimario}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <Button variant="outlined" color="primary" onClick={() => handleDocumentOpen(documento)} startIcon={<FileOpenIcon />}>
                        {t("open")}
                      </Button>
                      <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={() => handleDownloadFile(documento)} startIcon={<DownloadIcon />}>
                        {t("download")}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography fontWeight="bold">
          {t("upload_id")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {record.id || "N/A"}
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography fontWeight="bold">
          {t("upload_datetime")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {record.dataInvioInConservazione ? convertTimestamp(record.dataInvioInConservazione) : "N/A"}
          </Typography>
        </Typography>
      </Grid>

      {metadataSections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {section.title === "Soggetti" && record.soggetti ? (
            <Grid item xs={12}>
              <Accordion expanded={expandedSoggetti} onChange={toggleSoggettiExpansion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="soggetti-content" id="soggetti-header">
                  <Typography variant="body1" fontWeight="bold">
                    {t("Soggetti")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    {record.soggetti.map((soggetto, soggettoIndex) => (
                      <Grid item container spacing={1} key={soggettoIndex}>
                        <Grid item xs={12}>
                          <Typography variant="body1" fontWeight="bold">
                            {`${t("Soggetto")} ${soggettoIndex + 1}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {Object.keys(soggetto).map((key, keyIndex) =>
                          soggetto[key] ? (
                            <Grid item xs={12} sm={12} md={6} key={keyIndex}>
                              <Typography variant="body2" fontWeight="bold">
                                {metadataSchema[key]?.label || key}
                                <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                                  {soggetto[key]}
                                </Typography>
                              </Typography>
                            </Grid>
                          ) : null
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            section.fields.map((fieldKey, fieldIndex) =>
              record[fieldKey] ? (
                <Grid item xs={12} sm={12} md={6} key={fieldIndex}>
                  <Typography variant="body1" fontWeight="bold">
                    {metadataSchema[fieldKey]?.label || fieldKey}
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      {record[fieldKey]}
                    </Typography>
                  </Typography>
                </Grid>
              ) : null
            )
          )}
        </React.Fragment>
      ))}
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
            </Grid>
            {DetailsForm}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 0.5,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
          data-testid="upload-details-modal"
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("close")} placement="top">
                  <IconButton onClick={onClose} color="error" edge="end">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{DetailsForm}</DialogContent>
        </Dialog>
      )}
    </>
  );
};

CDNFileDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  handleFileClick: PropTypes.func.isRequired,
};

export default CDNFileDetailsDialog;
