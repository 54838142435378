// React
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pwa-install
import { useReactPWAInstall } from "react-pwa-install";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

// Components
import { LoginSuccessful, LoginError, InstallBanner } from "ui-components/ORFeedbacks";
import Copyright from "ui-components/Copyright";

// Firebase
import { requestPermission } from "config/firebase";

// SafeTwin
import { seedFlow } from "SafeTwin/3S/seedFlow";

// UserOperations
import addFCMToken from "UserOperations/addFCMToken";

// A ---------------------------------------------------------------------- M

const Signin = () => {
  const { user, setLanguage, setSeedFlow, signIn } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const [showInstallBanner, setShowInstallBanner] = useState(supported() && !isInstalled());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const tag = location.state?.tag;

  useEffect(() => {
    setLanguage("it");
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const handleClick = () => {
    pwaInstall({
      title: t("install_pwa_title"),
      description: t("install_pwa_description"),
    })
      .then(() => console.log(t("installation_success")))
      .catch(() => console.log(t("installation_error")));
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signIn(email, password);
      const token = await requestPermission();
      if (token) {
        await addFCMToken(userCredential.user.uid, token);
      }
      const isSeeded = await seedFlow(userCredential.user, password);
      setSeedFlow(isSeeded);
      setOpenSuccess(true);
      console.log("Signin successful.");
    } catch (error) {
      console.error("Signin error:", error.message);
      setPassword("");
      setOpenError(true);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate(tag ? `/${tag}` : "/");
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url('/images/signin_bg.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 4,
              boxShadow: 4,
              borderRadius: 4,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold" mb="5%">
              {t("sign_in")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email")}
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-label={t("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label={t("password")}
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  aria-label={t("password")}
                />
                <Link to="/reset-password" style={{ textDecoration: "none" }}>
                  <Typography variant="body2" color="primary" align="right">
                    {t("reset_password")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={handleSignIn} aria-label={t("sign_in")} sx={{ py: 2, borderRadius: 3 }}>
                  {t("sign_in")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ mt: 2, pb: 2 }}>
          <Copyright />
        </Box>
      </Box>

      {showInstallBanner && <InstallBanner showInstallBanner={showInstallBanner} handleCloseInstallBanner={() => setShowInstallBanner(false)} handleClick={handleClick} />}
      {openSuccess && <LoginSuccessful open={openSuccess} handleClose={handleCloseSuccess} />}
      {openError && <LoginError open={openError} handleClose={handleCloseError} />}
    </>
  );
};

export default Signin;
