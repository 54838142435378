// sendCertificationStreamConfig
import sendCertificationStreamConfig from "./sendCertificationStreamConfig";

// sendDataStreamConfig
import sendDataStreamConfig from "./sendDataStreamConfig";

// eventLogger
import logEvent from "eventLogger/logEvent";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genConfigRecord = async (isPublic, userID, keypair, dataBody, databoxID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    // Certification stream
    const dataCertification = await sendCertificationStreamConfig(keypair, dataBody, timestamp, databoxID);

    if (!dataCertification.txid) {
      // Log certification error
      await logEvent(
        databoxID,
        "certification_error",
        timestamp,
        "Certification stream failed for cfg record.",
        {
          dataType: "cfg",
          errorMessage: "Missing txid in certification stream.",
          certificationStatus: false,
        },
        userID
      );

      console.error("Error in genConfigRecord: missing txid");

      return {
        success: false,
        error: "Error in genConfigRecord: missing txid",
      };
    }

    // Data stream
    const result = await sendDataStreamConfig(isPublic, userID, dataBody, dataCertification, timestamp, databoxID);

    if (!result.success) {
      // Log data stream error
      await logEvent(
        databoxID,
        "certification_error",
        timestamp,
        "Data stream failed for cfg record.",
        {
          certificationID: dataCertification.txid,
          dataType: "cfg",
          errorMessage: result.error,
          certificationStatus: false,
        },
        userID
      );
    } else {
      await logEvent(
        databoxID,
        "certification",
        timestamp,
        "Certification successfully performed for cfg record.",
        {
          certificationID: dataCertification.txid,
          dataType: "cfg",
          certificationStatus: true,
        },
        userID
      );
    }

    console.log(result);

    return result;
  } catch (error) {
    console.error("Error in genConfigRecord:", error.message);

    // Log a generic error for the databox
    await logEvent(
      databoxID,
      "certification_error",
      timestamp,
      "An unexpected error occurred during the record certification process.",
      {
        dataType: "cfg",
        errorMessage: error.message,
        certificationStatus: false,
      },
      userID
    );

    const result = {
      success: false,
      error: error.message,
    };

    console.log(result);

    return result;
  }
};

export default genConfigRecord;
