// firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const fetchTotalRecordsCount = async (tagId, type, isTagGroupMember) => {
  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");
    let baseQuery = query(recordsRef, where("type", "==", type));

    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    const snapshot = await getDocs(baseQuery);
    return snapshot.size;
  } catch (error) {
    console.error("Error fetching total record count:", error.message);
    return 0;
  }
};

export default fetchTotalRecordsCount;
