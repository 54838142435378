// React
import React, { useState, useMemo } from "react";

// Prop-types
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Checkbox,
  Divider,
} from "@mui/material";
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// DatakeyOperations
import setCanCreateDataboxFlag from "DatakeyOperations/setCanCreateDataboxFlag";
import unsetCanCreateDataboxFlag from "DatakeyOperations/unsetCanCreateDataboxFlag";

// Utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const DataKeysTableHead = () => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "value", label: t("value") },
      { id: "timestamp", label: t("creation_date") },
      { id: "canCreateDatabox", label: conservSostL1 ? t("can_create_archive") : t("can_create_databox") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "canCreateDatabox" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataKeysTableToolbar = ({ handleOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold" sx={{ color: "gray" }}>
          {t("datakeys")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item xs={12} sm={9} />
        <Grid item container sm={3} justifyContent="flex-end">
          {isMobile ? null : (
            <Button variant="outlined" fullWidth sx={{ py: 1.5 }} onClick={handleOpen} startIcon={<AddIcon />} data-testid="create-datakey-button">
              {t("new_datakey")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataRow = ({ datakey }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showDataKey, setShowDataKey] = useState(false);
  const [canCreate, setCanCreate] = useState(datakey.canCreateDatabox || false);

  const toggleDataKeyVisibility = () => {
    setShowDataKey(!showDataKey);
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setCanCreate(isChecked);
    if (isChecked) {
      await setCanCreateDataboxFlag(datakey.id);
    } else {
      await unsetCanCreateDataboxFlag(datakey.id);
    }
  };

  const DataKeyInfo = ({ name, createdOn }) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle1" fontWeight="bold">
        {name || "N/A"}
      </Typography>
      <Typography variant="body2">{createdOn ? convertTimestampDate(createdOn) : "N/A"}</Typography>
    </Box>
  );

  const DataKeyVisibility = ({ datakey, showDataKey, toggleVisibility }) => (
    <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
      <Typography variant="body2" sx={{ mr: 1 }}>
        {showDataKey ? datakey : "••••••"}
      </Typography>
      <Tooltip title={showDataKey ? t("hide") : t("show")} placement="top">
        <IconButton onClick={toggleVisibility} color="primary">
          {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );

  if (isMobile) {
    return (
      <Box key={datakey.id} sx={{ p: 2, borderBottom: "1px solid #e0e0e0", cursor: "pointer", "&:hover": { backgroundColor: "#f0f0f0" } }}>
        <DataKeyInfo name={datakey.name} createdOn={datakey.created_on} />
        <DataKeyVisibility datakey={datakey.datakey} showDataKey={showDataKey} toggleVisibility={toggleDataKeyVisibility} />
        <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
          <Checkbox checked={canCreate} onChange={handleCheckboxChange} inputProps={{ "aria-label": "controlled" }} />
          <Typography variant="body2" fontWeight="bold">
            {conservSostL1 ? t("can_create_archive") : t("can_create_databox")}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
      <TableCell align="left">{datakey.name || "N/A"}</TableCell>
      <TableCell align="left" sx={{ minWidth: "180px", maxWidth: "180px" }} size="small">
        <span style={{ marginRight: "10px" }}>{showDataKey ? datakey.datakey : "••••••"}</span>
        <Tooltip title={showDataKey ? t("hide") : t("show")} placement="top">
          <IconButton onClick={toggleDataKeyVisibility} color="primary">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="left">{datakey.created_on ? convertTimestampDate(datakey.created_on) : "N/A"}</TableCell>
      <TableCell align="right" size="small">
        <Checkbox checked={canCreate} onChange={handleCheckboxChange} inputProps={{ "aria-label": "controlled" }} />
      </TableCell>
    </TableRow>
  );
};

const DataKeysTable = ({ dataKeys, handleOpen }) => {
  console.log("Datakeys:", dataKeys);

  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKeys.length) : 0;

  return (
    <Box>
      <DataKeysTableToolbar handleOpen={handleOpen} />
      <br />
      <Divider />
      <TableContainer>
        <Table data-testid="datakeys-table">
          {!isMobile && <DataKeysTableHead />}
          <TableBody>
            {(isMobile ? dataKeys : dataKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((datakey) => {
              return <DataRow key={datakey.id} datakey={datakey} />;
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={dataKeys.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

DataKeysTable.propTypes = {
  dataKeys: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default DataKeysTable;
