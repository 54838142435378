import { calculateSHA512 } from "../crypto/cryptolibsodium";
import { decryptSeedBySHA512 } from "../crypto/cryptoseed";
import getServerEncryptedSeed from "./getServerEncryptedSeed";

// A ---------------------------------------------------------------------- M

const SALT = "armilis";

/**
 * Decrypts the server's encrypted seed for a given user.
 *
 * This function retrieves the encrypted seed and nonce for a user identified by `uuid`,
 * then uses the SHA512 hash of the user's password concatenated with a fixed salt to decrypt the seed.
 *
 * @param {string} uuid - The user ID for which the encrypted seed needs to be decrypted.
 * @param {string} password - The password of the user, used in generating the decryption key.
 * @returns {Promise<string>} A promise that resolves to the decrypted seed, or rejects with an error if decryption fails.
 */
export const decryptServerSeed = async (uuid, password) => {
  try {
    // Server sends the current user's encrypted seed and nonce
    const { server_encrypted_seed, nonce } = await getServerEncryptedSeed(uuid);

    // Calculate SHA512(password + salt)
    const sha512 = calculateSHA512(password + SALT);

    // Decrypt the server encrypted seed using previously generated sha512 as key
    const decrypted_seed = decryptSeedBySHA512(server_encrypted_seed, sha512, nonce);

    return decrypted_seed;
  } catch (error) {
    console.error("Error in decrypting server seed:", error.message);
    return null;
  }
};
