// Libsodium.js wrapper functions

const _sodium = require("libsodium-wrappers");
const _sodium_sumo = require("libsodium-wrappers-sumo");
var sodium;
var sodium_sumo;

// A ---------------------------------------------------------------------- M

(async () => {
  try {
    await _sodium.ready;
    sodium = _sodium;
    await _sodium_sumo.ready;
    sodium_sumo = _sodium_sumo;
  } catch (error) {
    console.error("Error initializing sodium libraries:", error.message);
  }
})();

// Convert from bytearray to hex
export const toHex = (bytearray) => {
  try {
    const hex = sodium.to_hex(bytearray);
    return hex;
  } catch (error) {
    console.error("Error converting from bytearray to hex:", error.message);
  }
};

// Convert from hex to bytearray
export const fromHex = (hex) => {
  try {
    const bytearray = sodium.from_hex(hex);
    return bytearray;
  } catch (error) {
    console.error("Error converting from hex to bytearray:", error.message);
  }
};

// Convert from bytearray to base64
export const toBase64 = (bytearray) => {
  try {
    const base64 = sodium.to_base64(bytearray, sodium.base64_variants.ORIGINAL_NO_PADDING);
    return base64;
  } catch (error) {
    console.error("Error converting from bytearray to base64:", error.message);
  }
};

// Convert from base64 to bytearray
export const fromBase64 = (base64) => {
  try {
    const bytearray = sodium.from_base64(base64, sodium.base64_variants.ORIGINAL_NO_PADDING);
    return bytearray;
  } catch (error) {
    console.error("Error converting from base64 to bytearray:", error.message);
  }
};

// Comparing two hashes
export const compareSHA256 = (hash1, hash2) => {
  return hash1 === hash2;
};

// SHA256 hash as byte array - input can be string or bytearray, the result will be the same
export const calculateSHA256 = (string) => {
  try {
    const sha256 = sodium_sumo.crypto_hash_sha256(string);
    return sha256;
  } catch (error) {
    console.error("Error calculating SHA256:", error.message);
  }
};

// SHA512 hash as byte array - input can be string or bytearray, the result will be the same
export const calculateSHA512 = (string) => {
  try {
    const sha512 = sodium_sumo.crypto_hash_sha512(string);
    return sha512;
  } catch (error) {
    console.error("Error calculating SHA512:", error.message);
  }
};

// Asymmetric encryption key pair deterministically derived from a single seed
export const generateSeedKeyPair = (seed) => {
  try {
    const keypair = sodium.crypto_box_seed_keypair(seed);
    return {
      pk: keypair.publicKey,
      sk: keypair.privateKey,
    };
  } catch (error) {
    console.error("Error generating seed keypair:", error.message);
  }
};

// Ed25519 key pair deterministically derived from a single seed
export const generateSeedEd25519KeyPair = (seed) => {
  try {
    const keypair = sodium.crypto_sign_seed_keypair(seed);
    return {
      pk: keypair.publicKey,
      sk: keypair.privateKey,
    };
  } catch (error) {
    console.error("Error generating seed Ed25519 keypair:", error.message);
  }
};

// Generate a key pair
export const generateKeyPair = () => {
  try {
    const keypair = sodium.crypto_box_keypair();
    return {
      pk: keypair.publicKey,
      sk: keypair.privateKey,
    };
  } catch (error) {
    console.error("Error generating keypair:", error.message);
  }
};

// Generate n bytes random
export const genRnd = (n) => {
  try {
    const randomBytes = sodium.randombytes_buf(n);
    return randomBytes;
  } catch (error) {
    console.error("Error random bytes:", error.message);
  }
};

// Generate n bytes random string of lowercase chars
export const genRndString = (n) => {
  try {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";

    // Generate n random bytes
    const randomBytes = sodium.randombytes_buf(n);

    // Transform the random bytes into a string of n alphabetical characters
    let randomString = "";

    for (const byte of randomBytes) {
      randomString += alphabet[byte % 26];
    }

    return randomString;
  } catch (error) {
    console.error("Error generating random string of lowercase chars:", error.message);
  }
};

// Generate n bytes random string of lowercase alphanumeric chars
export const genRndStringAlphanumericLowercase = (n) => {
  try {
    const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";

    // Generate n random bytes
    const randomBytes = sodium.randombytes_buf(n);

    // Transform the random bytes into a string of n alphanumerical lowercase characters
    let randomString = "";

    for (const byte of randomBytes) {
      randomString += alphabet[byte % alphabet.length];
    }

    return randomString;
  } catch (error) {
    console.error("Error generating random string of lowercase alphanumeric chars:", error.message);
  }
};

// Generate n bytes random string of printable charaters
export const genRndStringPrintable = (n) => {
  try {
    /* const alphabet = '!"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~'; */
    const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    // Generate n random bytes
    const randomBytes = sodium.randombytes_buf(n);

    // Transform the random bytes into a string of n printable characters
    let randomString = "";

    for (const byte of randomBytes) {
      randomString += alphabet[byte % alphabet.length];
    }

    return randomString;
  } catch (error) {
    console.error("Error generating random string of printable characters:", error.message);
  }
};

// Derive public key from private key
export const derivePubKey = (pvtkey) => {
  try {
    const pubkey = sodium.crypto_scalarmult_base(pvtkey);
    return pubkey;
  } catch (error) {
    console.error("Error deriving public key from private key:", error.message);
  }
};

// Asymmetric encryption - cryptobox
export const encryptCryptoBoxSeal = (plaintext, pubkey) => {
  try {
    const ciphertext = sodium.crypto_box_seal(plaintext, pubkey);
    return ciphertext;
  } catch (error) {
    console.error("Error encrypting cryptobox seal for asymmetric encryption:", error.message);
  }
};

// Asymmetric decryption - cryptobox
export const decryptCryptoBoxSeal = (ciphertext_hex, pubkey, pvtkey) => {
  try {
    const plaintext = sodium.crypto_box_seal_open(fromHex(ciphertext_hex), pubkey, pvtkey);
    return plaintext;
  } catch (error) {
    console.error("Error decrypting cryptobox seal for asymmetric decryption:", error.message);
  }
};

// Symmetric encryption - secretbox
export const encryptSecretBox = (plaintext, secret, nonce = fromHex(window.localStorage.getItem("nonce"))) => {
  try {
    const ciphertext = toHex(sodium.crypto_secretbox_easy(plaintext, nonce, secret));
    return ciphertext;
  } catch (error) {
    console.error("Error encrypting secretbox for symmetric encryption:", error.message);
  }
};

// Symmetric decryption - secretbox
export const decryptSecretBox = (ciphertext, secret, nonce = fromHex(window.localStorage.getItem("nonce"))) => {
  try {
    const plaintext = sodium.crypto_secretbox_open_easy(ciphertext, nonce, secret);
    return plaintext;
  } catch (error) {
    console.error("Error decrypting secretbox for symmetric decryption:", error.message);
  }
};

// Ed25519 signature generation
export const ed25519Sign = (message, pvt) => {
  try {
    const signature = sodium.crypto_sign_detached(message, pvt);
    return signature;
  } catch (error) {
    console.error("Error ed25519 signing:", error.message);
  }
};

// Ed25519 signature verification
export const ed25519Verify = (signature, message, pbk) => {
  try {
    const verification = sodium.crypto_sign_verify_detached(signature, message, pbk);
    return verification;
  } catch (error) {
    console.error("Error ed25519 verifying:", error.message);
  }
};
