// sendCertificationStreamImage
import sendCertificationStreamImage from "./sendCertificationStreamImage";

// sendDataStreamImage
import sendDataStreamImage from "./sendDataStreamImage";

// eventLogger
import logEvent from "eventLogger/logEvent";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genImageRecord = async (isPublic, userID, keypair, dataBody, databoxID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    if (databoxID) {
      // Certification stream
      const dataCertification = await sendCertificationStreamImage(keypair, dataBody, timestamp, databoxID);

      if (!dataCertification.txid) {
        // Log certification error
        await logEvent(
          databoxID,
          "certification_error",
          timestamp,
          "Certification stream failed for img record.",
          {
            dataType: "img",
            errorMessage: "Missing txid in certification stream.",
            certificationStatus: false,
          },
          userID
        );

        console.error("Error in genImageRecord: missing txid");

        return {
          success: false,
          error: "Error in genImageRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamImage(isPublic, userID, dataBody, dataCertification, timestamp, databoxID);

      if (!result.success) {
        // Log data stream error
        await logEvent(
          databoxID,
          "certification_error",
          timestamp,
          "Data stream failed for img record.",
          {
            certificationID: dataCertification.txid,
            dataType: "img",
            errorMessage: result.error,
            certificationStatus: false,
          },
          userID
        );
      } else {
        await logEvent(
          databoxID,
          "certification",
          timestamp,
          "Certification successfully performed for img record.",
          {
            certificationID: dataCertification.txid,
            dataType: "img",
            certificationStatus: true,
          },
          userID
        );
      }

      console.log(result);

      return result;
    } else {
      // Certification stream
      const dataCertification = await sendCertificationStreamImage(keypair, dataBody, timestamp);

      if (!dataCertification.txid) {
        console.error("Error in genImageRecord: missing txid");
        return {
          success: false,
          error: "Error in genImageRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamImage(isPublic, userID, dataBody, dataCertification, timestamp);

      console.log(result);

      return result;
    }
  } catch (error) {
    console.error("Error in genImageRecord:", error.message);

    if (databoxID) {
      // Log a generic error for the databox
      await logEvent(
        databoxID,
        "certification_error",
        timestamp,
        "An unexpected error occurred during the record certification process.",
        {
          dataType: "img",
          errorMessage: error.message,
          certificationStatus: false,
        },
        userID
      );
    }

    const result = {
      success: false,
      error: error.message,
    };

    console.log(result);

    return result;
  }
};

export default genImageRecord;
