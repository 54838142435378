// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// react-copy-to-clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

// Components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const ExportSecret = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const exportForm = (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid container alignItems="center" justifyContent={isMobile ? "center" : "space-between"}>
        <Grid item>
          <Typography variant="h6">{t("export_secret")}</Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton onClick={props.handleClose} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <TextField
        margin="dense"
        required
        fullWidth
        id="Mnemonic-Export"
        label={t("_24_words_secret")}
        name="Mnemonic-Export"
        rows={5}
        multiline
        disabled
        value={props.mnemonic}
        InputProps={{
          endAdornment: (
            <CopyToClipboard text={props.mnemonic} onCopy={props.handleCopySuccess}>
              <Tooltip title={!props.copied ? t("copy") : t("copied")} placement="top">
                <IconButton color="primary">
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          ),
        }}
        onChange={(e) => props.setMnemonic(e.target.value)}
      />
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={props.handleClose}
      onOpen={() => props.setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {exportForm}
    </SwipeableDrawer>
  ) : (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="md">
      {exportForm}
    </Dialog>
  );
};

export default ExportSecret;
