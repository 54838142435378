// firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const fetchTotalRecordsCountWithTypes = async (tagId, types, isTagGroupMember) => {
  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");

    let baseQuery = query(recordsRef, where("type", "in", types));

    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    const snapshot = await getDocs(baseQuery);

    console.log(`Total records count for types ${types}:`, snapshot.size);
    return snapshot.size;
  } catch (error) {
    console.error("Error fetching total record count:", error.message);
    return 0;
  }
};

export default fetchTotalRecordsCountWithTypes;
