// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Typography, Stack, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// A ---------------------------------------------------------------------- M

const TagToolbarPublic = ({ tag }) => {
  const { t } = useTranslation();

  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  const TagInformation = ({ tag }) => (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body2">
            <b>{t("id")}:</b> {tag.id}
          </Typography>
          <Typography variant="body2">
            <b>{t("name")}:</b> {tag.name || "N/A"}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3} data-testid="databox-information-card">
      <Grid item xs={12}>
        <Accordion expanded={accordionOpen} onChange={handleAccordionToggle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" fontWeight="bold">
              {tag.name || "N/A"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ my: 2 }} />
            <TagInformation tag={tag} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default TagToolbarPublic;
