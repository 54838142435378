// React
import React from "react";

// @mui
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InfoIcon from "@mui/icons-material/Info";

// A ---------------------------------------------------------------------- M

const MetadataField = ({ fieldKey, metadata, documentProperties, handleChange, handleClickOpen }) => {
  const { label, type, visible, helperText, options, readOnly, required } = metadata[fieldKey];

  if (!visible(documentProperties)) {
    return null;
  }

  const resolvedOptions = typeof options === "function" ? options(documentProperties) : options;

  const isRequired = typeof required === "function" ? required(documentProperties) : required;

  const inputStyles = {
    backgroundColor: isRequired ? (readOnly ? "#f5f5f5" : "#fff8e1") : readOnly ? "f5f5f5" : "inherit",
  };

  switch (type) {
    case "text":
      return (
        <Grid item xs={12} sm={4}>
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            required={isRequired}
            value={documentProperties[fieldKey] || ""}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            sx={inputStyles}
            InputProps={{
              readOnly: readOnly,
              endAdornment: helperText && (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickOpen(helperText)}>
                    <InfoIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      );

    case "select":
      return (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined" disabled={readOnly} required={isRequired}>
            <InputLabel>{label}</InputLabel>
            <Select
              value={documentProperties[fieldKey] || ""}
              onChange={(e) => handleChange(fieldKey, e.target.value)}
              input={
                <OutlinedInput
                  label={label}
                  sx={inputStyles}
                  endAdornment={
                    helperText && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClickOpen(helperText)}>
                          <InfoIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              }
            >
              {resolvedOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );

    case "datetime":
      return (
        <Grid item xs={12} sm={4}>
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            required={isRequired}
            type="datetime-local"
            value={documentProperties[fieldKey] || ""}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            sx={inputStyles}
            InputProps={{
              readOnly: readOnly,
              endAdornment: helperText && (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickOpen(helperText)}>
                    <InfoIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );

    default:
      return null;
  }
};

export default MetadataField;
