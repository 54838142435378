// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const AccountCard = () => {
  const { user, clientData } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={1} data-testid="account-information-card">
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "gray" }}>
          {t("account_information")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
            <b>{t("user_id")}</b>: {user.uid || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
            <b>{t("email")}</b>: {user.email || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isMobile ? "body2" : "body1"}>
            <b>{t("customer_name")}</b>: {clientData ? clientData.companyName || clientData.id : "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountCard;
