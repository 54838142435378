const unixTimestampInSeconds = () => {
  try {
    const unixTimestampInSeconds = Math.floor(Date.now() / 1000);
    const unixTimestampString = unixTimestampInSeconds.toString();
    return unixTimestampString;
  } catch (error) {
    console.error("Get current unix timestamp in seconds failed:", error.message);
  }
};

export default unixTimestampInSeconds;
