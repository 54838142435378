// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Typography, Stack, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// Utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const GroupToolbar = ({ group }) => {
  const { t } = useTranslation();

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={3} data-testid="group-information-card">
      <Grid item xs={12}>
        <Accordion expanded={accordionOpen} onChange={handleAccordionToggle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" fontWeight="bold">
              {group.name || "N/A"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ my: 2 }} />
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    <b>{t("id")}:</b> {group.id}
                  </Typography>
                  <Typography variant="body2">
                    <b>{t("name")}:</b> {group.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <b>{t("creation_date")}</b>: {group.created_on ? convertTimestampDate(group.created_on) : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <b>{t("creator")}</b>: {group.creator_email || "N/A"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default GroupToolbar;
