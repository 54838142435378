// Firebase
import { db } from "config/firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";

import { encryptSeedBySHA512 } from "../crypto/cryptoseed";
import { mnemonicToEntropy } from "../crypto/bip39/bip39";
import { fromHex, toHex, calculateSHA512, generateSeedEd25519KeyPair } from "../crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const SALT = "armilis";

/**
 * Encrypts and stores a user's mnemonic in Firestore, and sets its public key in 'usercontacts'.
 * This function converts the mnemonic to entropy, generates a key pair, and encrypts the seed using SHA512 as key.
 * The encrypted seed, nonce and public_key are then stored in Firestore under 'userseeds' and 'usercontacts'.
 *
 * @param {Object} user - The user object containing the uid property.
 * @param {string} password - The password of the user, used for seed encryption.
 * @param {string} mnemonic - The mnemonic phrase to be stored.
 */
export const loadMnemonic = async (user, password, mnemonic) => {
  try {
    const uuid = user.uid;

    // Calculate entropy
    const seed = mnemonicToEntropy(mnemonic);
    const pbk = toHex(generateSeedEd25519KeyPair(fromHex(seed)).pk);

    // Encrypt seed using sha512 of password and salt as key
    const sha512 = calculateSHA512(password + SALT);
    const { encrypted_seed, nonce } = encryptSeedBySHA512(seed, sha512);

    // Store encrypted seed in 'userseeds'
    const userSeedDocRef = doc(db, "userseeds", uuid);
    await setDoc(userSeedDocRef, {
      encrypted_seed,
      nonce,
      public_key: pbk,
    });

    // Store public key in 'usercontacts'. Merge public key into 'usercontacts' without overwriting other attributes
    const userContactsDocRef = doc(db, "usercontacts", uuid);
    await updateDoc(userContactsDocRef, {
      public_key: pbk,
    });

    return pbk;
  } catch (error) {
    console.error("Error in loadMnemonic:", error.message);
    return null;
  }
};
